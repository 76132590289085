<template>
  <div class="result-table-container">
    <table>
      <tr>
        <th>Answered</th>
        <th>Unanswered</th>
        <th>Score</th>
        <th>Highest Score</th>
      </tr>
      <tr>
        <td>{{ resultData.answered }}</td>
        <td>{{ resultData.unanswered }}</td>
        <td>{{ resultData.score }}</td>
        <td>{{ resultData.highestScore }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "ResultTable",
  props: {
    resultData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style scoped>
table {
  overflow-x: hidden;
  border-collapse: collapse;
  margin-right: 100px;
}

table,
th,
td {
  border: 1px solid black;
  padding: 10px;
}
.result-table-container {
  margin-left: 100px;
  margin-bottom: 20px;
  margin-right: 100px;
}
table {
  width: 100%;
}
@media all and (max-width: 550px) {
  .result-table-container {
    width: 90vw;
    margin-left: 20px;
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>
