<template>
  <div>
    <ResultTable :result-data="resultData"></ResultTable>
    <GeneralPerformanceTable
      :general-performance-data="sectionArray"
    ></GeneralPerformanceTable>
    <div v-if="user.data.admin" class="remarks">
      <div class="short-remark">
        <h4>Remarks:</h4>

        <textarea class="remark-text form-control" v-model="remarksText">
        </textarea>
      </div>
      <div class="performance-measure">
        <div class="btn-group">
          <button
            :disabled="isDisabled"
            type="button"
            class="btn btn-dark dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ getPerformanceRating() }}
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="javascript:void(0);"
              :class="{ active: active === 0 }"
              @click="changePerformance(0)"
              >Well Done</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0);"
              :class="{ active: active === 1 }"
              @click="changePerformance(1)"
              >Sufficiently prepared
            </a>
            <a
              class="dropdown-item"
              href="javascript:void(0);"
              :class="{ active: active === 2 }"
              @click="changePerformance(2)"
              >There but can do better</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0);"
              :class="{ active: active === 3 }"
              @click="changePerformance(3)"
              >Almost there</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0);"
              :class="{ active: active === 4 }"
              @click="changePerformance(4)"
              >Needs improvement</a
            >
          </div>
        </div>
        <button class="btn btn-primary go-back" @click="goBack">go back</button>
      </div>

      <button
        class="submit btn btn-success"
        @click="submitReview"
        ref="btnSubmit"
        :disabled="isDisabled"
      >
        Submit remarks
      </button>
    </div>
    <TeacherReview
      class="tr"
      v-if="!user.data.admin"
      :rating="rating"
      :remark="remarks"
    ></TeacherReview>
  </div>
</template>

<script>
import ResultTable from "@/components/AnalysisComponents/ResultTable";
import GeneralPerformanceTable from "@/components/AnalysisComponents/GeneralPerformanceTable";
import store from "@/store";
import db from "@/firebase";
import { mapGetters } from "vuex";
import TeacherReview from "@/components/AnalysisComponents/TeacherReview";
import { testTakers, testStats, questions, getTest } from "@/Utils/global";

export default {
  name: "BriefAnalysis",
  components: { TeacherReview, GeneralPerformanceTable, ResultTable },
  data() {
    return {
      resultData: {
        correct: 0,
        incorrect: 0,
        unanswered: 0,
        score: 0,
        air: 0,
        highestScore: 0,
        toa: 0,
        percentile: 0,
        answered: 0
      },
      test_id: 0,
      page_id: 0,
      user_id: 0,
      responses: [],
      correctAnswer: [],
      sectionArray: [],
      subjectiveQuestion: [],
      totalMarks: 0,
      active: -1,
      remarksText: "",
      disabled: false,
      rating: "Not Rated",
      remarks: "Not Reviewed"
    };
  },
  created() {
    // console.log(this.$route.params);
    this.test_id = this.$route.params.test_id;
    this.page_id = this.$route.params.page_id;
    this.user_id = store.state.user.data.uid;

    if (this.user.data.admin) {
      // console.log(this.$route.params);
      this.user_id = this.$route.query.user_test_id;
      this.page_id = this.$route.query.page_id;
      this.test_id = this.$route.query.test_id;
    }
    // console.log(this.user_id);
    this.fetchData();
  },

  methods: {
    async fetchData() {
      let stats = await testStats(this.page_id, this.test_id).then(doc => {
        // console.log(doc.data());
        if (doc.exists)
          this.resultData.highestScore = Math.max(...doc.data().score);
        else this.resultData.highestScore = 0;
      });

      let d = await testTakers(this.page_id, this.test_id, this.user_id);

      let data = d.data();
      if (!this.user.data.admin) {
        this.rating = data.performanceRating;
        this.remarks = data.remark;
        // console.log(data);
      }
      let correctAnswer = await questions(this.page_id, this.test_id);
      this.correctAnswer = correctAnswer.docs.map(doc => doc.data());
      this.correctAnswer = this.correctAnswer.sort(this.sort_questions);

      let questionId = {};
      this.correctAnswer.forEach((data, index) => {
        questionId[data.id] = index;
      });

      // console.log(data);
      this.responses = data["responses"];
      this.fillTableData();

      //topic Wise
      let sections = await getTest(this.page_id, this.test_id);

      this.sectionArray = sections.data().sections;

      let sectionId = {};
      this.sectionArray.forEach((data, index) => {
        data["sNo"] = index + 1;
        data["totalQuestions"] = 0;
        data["attempted"] = 0;
        data["totalMarks"] = 0;
        data["marks"] = 0;
        data["notEvaluated"] = 0;
        sectionId[data.section_name] = index;
      });

      correctAnswer.docs.forEach(answer => {
        let data = answer.data();

        let section = data.section;

        this.sectionArray[sectionId[section]]["totalMarks"] += data.marks;
        if (!("correct" in answer.data())) {
          this.sectionArray[sectionId[section]]["totalQuestions"]++;

          this.subjectiveQuestion.push(answer.data());
          return;
        } else if ("subType" in answer.data() && answer.data().subType == 0) {
          // console.log(answer.data());
        }

        let marks = this.responses[questionId[data.id]].marks;

        let response = this.responses[questionId[data.id]].response;

        this.sectionArray[sectionId[section]]["totalQuestions"]++;

        if (data.marks >= marks) {
          this.sectionArray[sectionId[section]]["marks"] += Number(marks);
        }

        // console.log(response);
        if (response !== -1 && response != null)
          this.sectionArray[sectionId[section]]["attempted"]++;
      });

      let sec = this.sectionArray;
      this.sectionArray.forEach((data, index) => {
        data["percentage"] = parseFloat(
          ((data.marks / data.totalMarks) * 100).toFixed(2)
        );
      });

      // if reviewed
      if (data.reviewed === true) {
        this.subjectiveQuestion.forEach(question => {
          if (
            this.responses[questionId[question.id]].response != null ||
            this.responses[questionId[question.id]].response != -1
          )
            this.sectionArray[sectionId[question.section]]["attempted"]++;
          this.sectionArray[sectionId[question.section]]["marks"] += Number(
            data.responses[questionId[question.id]].marks
          );
        });
      }
    },
    sort_questions(a, b) {
      if (parseInt(a.id) < parseInt(b.id)) return -1;
      else return 1;
    },
    fillTableData() {
      this.responses.forEach((response, index) => {
        this.resultData.score += Number(response.marks);
        if (response.response != -1 || response.response == null)
          this.resultData.answered++;
        else this.resultData.unanswered++;
        console.log(index);
        if (response.response === this.correctAnswer[index].correct)
          this.resultData.correct++;
        else this.resultData.incorrect++;
      });
    },
    changePerformance(index) {
      this.active = index;
    },
    goBack() {
      // console.log(this.user_id);
      this.$router.push({
        name: "ReviewTest",
        params: {
          page_id: this.page_id,
          test_id: this.test_id,
          user_test_id: this.user_id
        }
      });
    },
    async submitReview() {
      if (this.active == -1) {
        swal("Select Performance Rating");
        return;
      }
      let userRes = await db
        .collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("test_takers")
        .doc(this.user_id)
        .set(
          {
            remark: this.remarksText,
            performanceRating: this.getPerformanceRating()
          },
          { merge: true }
        )
        .then(res => {
          db.collection("test_completed_notifications" + this.page_id)
            .doc(this.user_id)
            .set(
              {
                rated: true
              },
              { merge: true }
            )
            .then(() => {
              this.goBack();
            });

          this.$refs.btnSubmit.innerText = "Submitted";
          // this.$refs.btnSubmit.
          this.disabled = true;
        });
    },
    getPerformanceRating() {
      switch (this.active) {
        case 0:
          return "Well Done";
        case 1:
          return "Sufficiently prepared";
        case 2:
          return "There but can do better";
        case 3:
          return " Almost there";
        case 4:
          return "Needs improvement";
        default:
          return "Performance Measure";
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    isDisabled() {
      return this.disabled;
    }
  }
};
</script>

<style scoped>
@media all and (max-width: 800px) {
  .tr {
    width: 90vw;
    margin: 20px;
  }
}

.table-head {
  margin-left: 50px;
  color: grey;
  font-size: 15px;
  font-family: cursive;
  font-style: italic;
}
.remarks {
  margin: 20px auto;
  width: 50vw;
}
.remark-text {
  width: 30vw;
  height: 130px;

  margin-left: 20px;
  margin-right: 20px;
}
.short-remark {
  display: flex;
  float: left;
}
.performance-measure {
  /*float: right;*/
}
.active {
  background-color: dodgerblue;
}
.submit {
  /*float: right;*/
  margin-top: 50px;
}
.go-back {
  margin: 10px;
}
</style>
