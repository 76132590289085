<template>
  <div class="general-performance-table">
    <div class="table-title-sort-container">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Sort by
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" @click="sortTable('sNo')"
            >Section Wise</a
          >
          <a class="dropdown-item" href="#" @click="sortTable('totalQuestion')"
            >Total Question
          </a>
          <a class="dropdown-item" href="#" @click="sortTable('attempted')"
            >Attempted</a
          >
          <a class="dropdown-item" href="#" @click="sortTable('totalMarks')"
            >Total Marks</a
          >
          <a class="dropdown-item" href="#" @click="sortTable('marks')"
            >Marks Obtained</a
          >
          <a class="dropdown-item" href="#" @click="sortTable('percentage')"
            >Percentage</a
          >
        </div>
      </div>
    </div>
    <div class="table-container" id="result-table">
      <table>
        <tr>
          <th>Sno</th>
          <th>Section Name</th>
          <th>Total Questions</th>
          <th>Attempted</th>
          <th>Total Marks</th>
          <th>Marks Obtained</th>
          <th>Percentage</th>
        </tr>

        <general-performance-row
          v-for="(generalPerformance, index) in generalPerform"
          :key="index"
          :general-performance-row="generalPerformance"
          :color="index % 2 === 1 ? '#dddddd' : 'white'"
        ></general-performance-row>
      </table>
    </div>
  </div>
</template>

<script>
import GeneralPerformanceRow from "@/components/AnalysisComponents/GeneralPerformanceRow";

export default {
  name: "GeneralPerformanceTable",
  components: { GeneralPerformanceRow },
  props: {
    generalPerformanceData: {
      type: Array,
      default: () => []
    }
  },
  created() {
    console.log(this.generalPerformanceData);
    this.generalPerform = this.generalPerformanceData;
    // for (let i = 0; i < 10; ++i) {
    //   let obj = {
    //     marks: i + 1,
    //     col1: "Lorem Ipsum Lorem Ipsum",
    //     col2: i * 12,
    //     col3: i * 24,
    //     col4: i * 53,
    //     col5: i * 2,
    //     col6: i * 6
    //   };
    //
    //   this.generalPerform.push(obj);
    // }
  },
  watch: {
    generalPerformanceData() {
      this.generalPerform = this.generalPerformanceData;
    }
  },
  data() {
    return {
      generalPerform: this.generalPerformanceData
    };
  },
  methods: {
    sortTable(key) {
      this.generalPerform.sort((a, b) =>
        a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
      );

      if (key !== "sNo") {
        this.generalPerform.reverse();
      }
    }
  }
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  padding: 10px;
}
/*.general-performance-table {*/
/*  overflow: scroll;*/
/*  width: vw;*/
/*  height: 210px;*/
/*}*/
/*.table-title-sort-container {*/
/*  width: vw;*/
/*}*/
/*tr:nth-child(2n) {*/
/*  background-color: gray;*/
/*}*/
table {
  width: 100%;
  overflow-x: hidden;
  border-collapse: collapse;
}

.general-performance-table {
  margin-left: 50px;
  margin-bottom: 20px;
  margin-right: 50px;
}
.btn-group {
  margin-bottom: 20px;
  margin-right: 0;
  float: right;
}
.table-title-sort-container {
  margin-right: 20px;
}
.table-head {
  /*margin-left: 50px;*/
  color: grey;
  font-size: 15px;
  font-family: cursive;
  float: left;
  font-style: italic;
}
.flip-list-move {
  transition: transform 1s;
}
@media all and (max-width: 750px) {
  .general-performance-table,
  table {
    /*background-color: chocolate!important; */
    width: 90vw;
    margin-left: 10px;
  }
  .table-container {
    width: 90vw;
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>
