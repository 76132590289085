<template>
  <div class="review-container">
    <div class="title">
      <h4 class="remark-title">Remark:</h4>
      <h4 class="performance">Performance Rating:{{ rating }}</h4>
    </div>
    <div class="remark" id="remark">
      <p>{{ remark }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherReview",
  props: ["remark", "rating"]
};
</script>

<style scoped>
.title {
  display: flex;
  margin-left: auto;
  order: 2;
}
/*.remark-title {*/
/*  float: left;*/
/*}*/
.performance {
  margin-left: auto;
  order: 2;
}
.review-container {
  width: 50vw;
  margin: 0 auto;
}
.remark {
  border: 1px black solid;
  padding: 10px;
}
@media all and(max-width: 800px) and(min-width:0px) {
  .review-container {
    width: 100vw !important;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
