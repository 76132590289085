<template>
  <tr :style="style">
    <td class="col0">{{ generalPerformanceRow.sNo }}</td>
    <td class="col1">
      {{ generalPerformanceRow.section_name }}
    </td>
    <td class="col2">{{ generalPerformanceRow.totalQuestions }}</td>
    <td class="col3">{{ generalPerformanceRow.attempted }}</td>
    <td class="col6">{{ generalPerformanceRow.totalMarks }}</td>
    <td class="col4">{{ generalPerformanceRow.marks }}</td>
    <td
      class="col4"
      :style="{
        backgroundColor: getColor(getPercent())
      }"
    >
      {{ getPercent() }}
    </td>
  </tr>
</template>

<script>
import { getPercentageBlockColor } from "@/Utils/global";

export default {
  name: "GeneralPerformanceRow",
  props: {
    generalPerformanceRow: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: "#000000"
    }
  },

  computed: {
    style() {
      return "background-color: " + this.color;
    }
  },
  data() {
    return {};
  },
  methods: {
    getColor(percent) {
      return getPercentageBlockColor(percent);
    },
    getPercent() {
      return (
        (this.generalPerformanceRow.marks /
          this.generalPerformanceRow.totalMarks) *
        100
      ).toFixed(2);
    }
  }
};
</script>

<style scoped>
th,
td {
  border: 1px solid black;
  padding: 10px;
}

.col0,
col2,
col3,
col4,
col5,
col6 {
  width: 10%;
}

.col1 {
  width: 40%;
}
</style>
